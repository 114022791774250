import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import { Container1440 } from "../customStyledComponents"
import AmenitieCard from "../subcomponents/amenitieCard"
import HotelCard from "../subcomponents/hotelCard";

const Container = styled.div`
  background: #F5F5F5;
`
const ContentContainer = styled(Container1440)`
  padding: 88px 32px;
  @media (min-width: 576px) {
    padding: 88px 48px;
  }
`
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  @media (min-width: 576px) {
    flex-direction: row;
    padding-bottom: 88px;
  }
`
const Header = styled.p`
  font-family: Test Founders Grotesk;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 32px;
  color: #132f60;
  mix-blend-mode: normal;
  margin: 0px 0px;
  text-transform: uppercase;
  max-width: fit-content;
  width: 100%;
  @media (min-width: 576px) {
    padding-right: 100px;
  }
  @media (min-width: 992px) {
    text-transform: uppercase;
    padding-right: 150px;
    width: 55%;
  }
  @media (min-width: 1440px) {
    padding-right: 130px;
    width: 38%;
  }
`

const Description = styled.p`
  font-family: Test Söhne;
  font-size: 24px;
  line-height: 40px;
  color: #132f60;
  mix-blend-mode: normal;
  margin: 0px 0px;
  padding-top: 32px;
  @media (min-width: 576px) {
    padding-top: 0px;
  }
  @media (min-width: 1440px) {
    padding-right: 140px;
  }
`

const List = styled.div`
  display: flex;
  justify-content: center;

  flex-wrap: wrap;
  /* column-gap: 24px;
  row-gap: 32px; */
  & > div:nth-of-type(n) {
    margin-bottom: 32px;
  }
  & > div:nth-of-type(4) {
    margin-bottom: 0px;
  }
  @media (min-width: 731px) {
    & > div:nth-of-type(n) {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  @media (min-width: 757px) {
    /*  row-gap: 24px; */
    & > div:nth-of-type(n) {
      margin-bottom: 0px;
      margin-left: 0px;
      margin-right: 0px;
    }
    & > div:nth-of-type(2n) {
      margin-left: 24px;
    }
    & > div:nth-of-type(-n + 2) {
      margin-bottom: 24px;
    }
  }
  @media (min-width: 1074px) {
    /* & > div:nth-of-type(2) {
      margin-left: 24px;
    }
    & > div:nth-of-type(3) {
      margin-left: 24px;
    }

    & > div:nth-of-type(n) {
     margin-bottom: 24px;
   }
   & > div:nth-of-type(4) {
     margin-bottom: 0px;
   } */
  }
  @media (min-width: 1074px) {
    flex-wrap: nowrap;
    & > div:nth-of-type(2n) {
      margin-left: 0px;
    }
    & > div:nth-of-type(n + 2) {
      margin-left: 24px;
    }
    & > div {
      margin-bottom: 0px !important;
    }
  }

  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    /*  & > div:not(:last-child) {
      margin-right: 24px;
      margin-bottom: 24px;
    } */
  }
`

const SectionHotels = props => {
  const data = useStaticQuery(graphql`
    query {
    img0: file(relativePath: { eq: "boho.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 270
            height: 360
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
            layout: FULL_WIDTH
          )
        }
      }
      img1: file(relativePath: { eq: "viento.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 270
            height: 360
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
            layout: FULL_WIDTH
          )
        }
      }
      img2: file(relativePath: { eq: "aguadilla.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 270
            height: 360
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
            layout: FULL_WIDTH
          )
        }
      }
    }
  `)

  const hotels = [
    {
      title: "Boho Beach Club ",
      description: "Boho Beach Club is the ideal place to unplug when visiting Puerto Rico’s west coast. ",
      image: data.img0,
      url: "https://bohobeachclubpr.com/",
      internal: false,
      linkName: "EXPLORE",
      anchor: true,
      key: 0,
    },
    {
      title: "Hotel Viento Beach Lodge ",
      description: "This charming hotel offers guests a cozy bohemian  aesthetic perfect for a small family or group of friends. ",
      image: data.img1,
      url: "https://vientobeachlodge.com/",
      internal: false,
      linkName: "EXPLORE",
      anchor: true,
      key: 1,
    },
    {
      title: "Hotel Aguadilla Lodge ",
      description: "Located in Aguadilla, this charming hotel is the ideal retreat for your next adventure.  ",
      image: data.img2,
      // url: "#",
      internal: true,
      linkName: "COMMING SOON",
      anchor: true,
      key: 2,
    },
  ]

  return (
    <Container>
      <ContentContainer>
        <TextContainer>
          <Header>Explore Our Other Hotels</Header>
          <Description>
          Explore our exceptional sister hotels, each offering unique luxury and unforgettable experiences. 
          Find your next extraordinary getaway with us!
          </Description>
        </TextContainer>
        <List>
          {hotels.map(hotels => (
            <HotelCard {...hotels} />
          ))}
        </List>
      </ContentContainer>
    </Container>
  )
}

SectionHotels.propTypes = {}

export default SectionHotels
