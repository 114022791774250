import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import classnames from "classnames"
import { validateFields } from "../../validation/Validation"
import IconFacebook from "../../images/social-facebook.svg"
import IconInstagram from "../../images/social-instagram.svg"
import Arrow from "../../images/arrow-black-right.svg"
import { AnimatedButton } from "../customStyledComponents"
import Input from "../inputs/input"
import Textarea from "../inputs/textarea"

const Container = styled.div`
  background-color: white;
  height: 100%;
`

const ContentContainer = styled.div`
  padding: 32px;
  padding-top: 88px;
  padding-bottom: 88px;
  max-width: 720px;

  .padding-left {
    padding-left: 0px;
  }
  .padding-right {
    padding-right: 0px;
  }
  .contact-lines {
    display: flex;
    flex-direction: column;
  }
  .social-media {
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 992px) {
    padding: 40px;
    .padding-left {
      padding-left: 16px;
    }
    .padding-right {
      padding-right: 0px;
    }
    .contact-lines {
      flex-direction: row;
    }
    .social-media {
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
    }
  }
  @media (min-width: 1400px) {
    padding: 88px;
  }
`

const Header = styled.p`
  font-family: Test Founders Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 32px;
  color: #132f60;
  mix-blend-mode: normal;
  margin: 0px 0px;
`
const ContacContainer = styled.div`
  margin-top: 32px;

  & > div:last-of-type {
    margin-top: 32px;
  }

  @media (min-width: 992px) {
    & > div:last-of-type {
      margin-top: 0px;
    }
  }
`
const ContactUrl = styled.a`
  word-break: break-word;
  font-family: Test Söhne;

  color: #132f60;
  mix-blend-mode: normal;
  margin: 0px 0px;
  text-decoration: none;
  cursor: pointer;
  font-size: 20px;
  line-height: 32px;
  & > a {
    text-decoration: none;
    font-family: Test Söhne;
    font-size: 20px;
    line-height: 32px;
    color: #132f60;
  }
  @media (min-width: 756px) {
    font-size: 24px;
    line-height: 24px;
  }
`

const ContactPhone = styled.p`
  /*  */
  font-family: Test Söhne;
  font-size: 20px;
  line-height: 32px;
  color: #132f60;
  mix-blend-mode: normal;
  margin: 0px 0px;
  & > a {
    text-decoration: none;
    font-family: Test Söhne;
    font-size: 20px;
    line-height: 32px;
    color: #132f60;
  }
  @media (min-width: 756px) {
    padding-top: 8px;
    font-size: 24px;
    line-height: 24px;
  }
`

const ContactAddress = styled.p`
  font-family: Test Söhne;
  font-size: 16px;
  line-height: 24px;
  color: #132f60;

  mix-blend-mode: normal;
  margin: 0px 0px;
  & > a {
    text-decoration: none;
    font-family: Test Söhne;
    font-size: 16px;
    line-height: 24px;
    color: #132f60;
  }
`
const SiteName = styled(ContactAddress)`
  font-weight: bold;
  @media (min-width: 992px) {
    padding-top: 32px;
  }
`
const IconContainer = styled.div`
  margin-top: 32px;
  @media (min-width: 992px) {
    margin-top: 0;
  }
`
const IconImg = styled.img`
  width: 24px;
  height: 24px;
`
const Link = styled.a`
  text-decoration: none;
  cursor: pointer;
  margin-right: 16px;
  @media (min-width: 992px) {
    &:last-of-type {
      margin-right: 0px;
    }
  }
`

const FormContact = styled.form``

const Button = styled(AnimatedButton)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  border: 1px solid #1e1f21;
  box-sizing: border-box;
  background-color: #ffffff;
  text-transform: uppercase;
  width: fit-content;
  cursor: pointer;
  font-family: Test Founders Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  color: #1e1f21;
  mix-blend-mode: normal;
  /*  margin: 1px; */

  img {
    margin-bottom: 0;
    margin-left: 8px;
    width: 10px;
    height: 7.5px;
  }

  /*   &:hover {
    border: 2px solid #1e1f21;
    margin: 0px;
  } */

  &.disabled {
    background-color: grey;
  }
`

const SubmitContainer = styled.div`
  margin-top: 32px;
  @media (min-width: 992px) {
    margin-top: 60px;
  }
`
const initalFormState = {
  firstname: {
    value: "",
    validateOnChange: false,
    error: "",
  },
  lastname: {
    value: "",
    validateOnChange: false,
    error: "",
  },
  email: {
    value: "",
    validateOnChange: false,
    error: "",
  },
  phone: {
    value: "",
    validateOnChange: false,
    error: "",
  },
  message: {
    value: "",
    validateOnChange: false,
    error: "",
  },
}
const FormCard = props => {
  const [fields, setFields] = useState(initalFormState)
  const [submitCalled, setSubmitCalled] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const { firstname, lastname, email, phone, message } = fields

  const textarea = useRef(null)

  const submit = async () => {
    let data = new FormData()
    data.append("firstname", firstname.value)
    data.append("lastname", lastname.value)
    data.append("email", email.value)
    data.append("phone", phone.value)
    data.append("message", message.value)

    fetch("https://formspree.io/f/xgedobre", {
      method: "POST",
      body: data,
      headers: {
        Accept: "application/json",
      },
    })
      .then(response => {
        try {
          if (response.status === 200 && response.ok) {
            setFields({ ...initalFormState })
            textarea.current.restoreHeight()
            setSubmitCalled(false)
            alert("Your message was sent successfully")
          } else {
            alert("ERROR, try again later!")
          }
        } catch (error) {
          console.log(error)
          alert("ERROR, try again later!")
        } finally {
          setSubmitting(false)
        }
      })
      .catch(error => {
        console.log(error)
        alert("ERROR, try again later!")
        setSubmitting(false)
      })
  }

  const handleBlur = (validationFunc, evt) => {
    const field = evt.target.name
    if (fields[field]["validateOnChange"] === false && submitCalled === false) {
      setFields({
        ...fields,
        [field]: {
          ...fields[field],
          validateOnChange: true,
          error: validationFunc ? validationFunc(fields[field].value) : "",
        },
      })
    }
    return
  }

  const handleChange = (validationFunc, evt) => {
    const field = evt.target.name
    const fieldVal = evt.target.value
    setFields({
      ...fields,
      [field]: {
        ...fields[field],
        value: fieldVal,
        error:
          fields[field]["validateOnChange"] && validationFunc
            ? validationFunc(fieldVal)
            : "",
      },
    })
  }

  const handleFormSubmit = evt => {
    evt.preventDefault()
    if (submitting) {
      return
    }
    const firstnameError = validateFields.validateFirstName(firstname.value)
    const emailError = validateFields.validateEmail(email.value)
    const phoneError = validateFields.validatePhoneNumber(phone.value)
    const messageError = validateFields.validateMessage(message.value)
    if (
      [firstnameError, emailError, phoneError, messageError].every(
        e => e === false
      )
    ) {
      setSubmitting(true)
      submit()
    } else {
      setFields({
        ...fields,
        firstname: {
          ...fields.firstname,
          validateOnChange: true,
          error: firstnameError,
        },
        email: {
          ...fields.email,
          validateOnChange: true,
          error: emailError,
        },
        phone: {
          ...fields.phone,
          validateOnChange: true,
          error: phoneError,
        },
        message: {
          ...fields.message,
          validateOnChange: true,
          error: messageError,
        },
      })
    }
  }

  return (
    <Container>
      <ContentContainer>
        <Header>GET IN TOUCH!</Header>
        <ContacContainer className="row g-0 row-cols-1">
          <div className="col padding-right">
            <div className="social-media">
              <div>
                <ContactUrl>
                  <a href="mailto:bellasurf@bohobeachclubpr.com">
                  bellasurf@bohobeachclubpr.com
                  </a>
                </ContactUrl>
                <ContactPhone>
                  <a href="tel:+1 787 872 6518">
                  +1 787 872 6518
                  </a>
                </ContactPhone>
              </div>
              <IconContainer>
                <Link
                  href="https://www.instagram.com/bellasurfinn/"
                  target="_blank"
                >
                  <IconImg src={IconInstagram} />
                </Link>
                <Link
                  href="https://www.facebook.com/BellaSurfInn/"
                  target="_blank"
                >
                  <IconImg src={IconFacebook} />
                </Link>
              </IconContainer>
            </div>
          </div>
          <div className="col">
            <SiteName>Bella Surf Inn&nbsp;</SiteName>
            <div className="contact-lines">
              <ContactAddress>Carr. 4466 Km 0.1&nbsp;</ContactAddress>
              <ContactAddress>Isabela - Isabela, PR 00662</ContactAddress>
            </div>
          </div>
        </ContacContainer>
        <FormContact className="row g-0" onSubmit={handleFormSubmit}>
          <div className="col-12 col-lg-6 padding-right">
            <Input
              type="text"
              value={firstname.value}
              placeholder="First Name"
              name="firstname"
              className={classnames(
                { "is-valid": firstname.error === false },
                { "is-invalid": firstname.error }
              )}
              onChange={evt =>
                handleChange(validateFields.validateFirstName, evt)
              }
              onBlur={evt => handleBlur(validateFields.validateFirstName, evt)}
              error={firstname.error}
            />
          </div>
          <div className="col-12 col-lg-6 padding-left">
            <Input
              type="text"
              value={lastname.value}
              placeholder="Last Name"
              name="lastname"
              className={classnames(
                { "is-valid": lastname.error === false },
                { "is-invalid": lastname.error }
              )}
              onChange={evt => handleChange(null, evt)}
              onBlur={evt => handleBlur(null, evt)}
              error={lastname.error}
            />
          </div>
          <div className="col-12 col-lg-6 padding-right">
            <Input
              type="email"
              value={email.value}
              placeholder="Email"
              name="email"
              className={classnames(
                { "is-valid": email.error === false },
                { "is-invalid": email.error }
              )}
              onChange={evt => handleChange(validateFields.validateEmail, evt)}
              onBlur={evt => handleBlur(validateFields.validateEmail, evt)}
              error={email.error}
            />
          </div>
          <div className="col-12 col-lg-6 padding-left">
            <Input
              type="phone"
              value={fields.phone.value}
              placeholder="Phone Number"
              name="phone"
              className={classnames(
                { "is-valid": phone.error === false },
                { "is-invalid": phone.error }
              )}
              onChange={evt =>
                handleChange(validateFields.validatePhoneNumber, evt)
              }
              onBlur={evt =>
                handleBlur(validateFields.validatePhoneNumber, evt)
              }
              error={phone.error}
            />
          </div>
          <div className="col-12">
            <Textarea
              value={message.value}
              placeholder="Message"
              name="message"
              rows="1"
              className={classnames(
                { "is-valid": message.error === false },
                { "is-invalid": message.error }
              )}
              onChange={evt =>
                handleChange(validateFields.validateMessage, evt)
              }
              onBlur={evt => handleBlur(validateFields.validateMessage, evt)}
              ref={textarea}
              error={message.error}
            />
          </div>
          <SubmitContainer className="col-12">
            <Button
              className={classnames({ disabled: submitting })}
              onClick={handleFormSubmit}
              onMouseDown={() => setSubmitCalled(true)}
            >
              SEND
              <img src={Arrow} />
            </Button>
          </SubmitContainer>
        </FormContact>
      </ContentContainer>
    </Container>
  )
}

FormCard.propTypes = {}

export default FormCard
