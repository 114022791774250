import * as React from "react"
import { Link } from "gatsby"
import "../css/main.scss"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SectionImage from "../components/sections/sectionImage"
import SectionRooms from "../components/sections/sectionRooms"
import SectionAmenities from "../components/sections/sectionAmenities"
import SectionHotels from "../components/sections/sectionHotels"
import SectionCollage from "../components/sections/sectionCollage"
import SectionContact from "../components/sections/sectionContact"
import SectionStream from "../components/sections/sectionStream"

const IndexPage = () => {
  // CORRECT - `useCallback` approach
  const onResize = React.useCallback(() => {
    console.error("")
  }, [])

  React.useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state

      const doc = document.documentElement
      doc.style.setProperty("--app-height", `${window.innerHeight}px`)
    }

    // Add event listener
    window.addEventListener("resize", handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  return (
    <Layout>
      <Seo title="Home" />
      <SectionImage />
      <SectionRooms />
      <SectionAmenities />
      <SectionStream />
      <SectionCollage />
      <SectionHotels />
      <SectionContact />
    </Layout>
  )
}

export default IndexPage
