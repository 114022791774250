import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

const Container = styled.div`
  position: relative;
  width: 100%;

  margin-top: 32px;

  textarea {
    resize: vertical;
    min-height: 37.6px;
  }
  input,
  textarea {
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-radius: 0px;
    border-bottom: solid 1px;
    border-color: #1e1f21;
    width: 100%;
    font-family: Test Söhne;
    font-size: 16px;
    line-height: 160%;
    letter-spacing: 0.5px;
    color: #1e1f21;
    padding: 0 0 11px 0;
    &::placeholder {
      font-family: Test Söhne;
      font-size: 16px;
      line-height: 160%;
      letter-spacing: 0.5px;
      color: #1E1F21;
    }
    &:focus-visible {
      color: #212529;
      background-color: #fff;
      /* border-color: #86b7fe; */
      outline: 0;
      /*  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); */
    }

    /*     &.is-valid {
      border-color: green;
    } */

    &.is-invalid {
      border-color: #d50d0d;
    }
  }

  /*   input:focus ~ label,
  input:not(:placeholder-shown) ~ label {
    transform: translateY(-30px) translateX(10px) scale(0.75);
  } */

  .placeholder {
    font-family: Test Söhne;
    font-size: 16px;
    line-height: 160%;
    letter-spacing: 0.5px;
    color: #1E1F21;
    z-index: 9999999999;
    // text-transform: uppercase;
    left: 0px;
    pointer-events: none;
    position: absolute;
    /* transform-origin: 0 50%; */
    transition: transform 200ms, font-size 200ms;
    background-color: transparent;
    top: 0px;
  }

  input:focus ~ .placeholder,
  input:not(:placeholder-shown) ~ .placeholder {
    transform: translateY(-20px);
    font-size: 10px;
  }

  input:hover ~ .placeholder {
    color: #1e1f21;
  }

  &.is-invalid > input:focus ~ .placeholder,
  &.is-invalid > input:not(:placeholder-shown) ~ .placeholder {
    color: #d50d0d;
  }

  &.is-invalid > label:last-of-type {
    visibility: visible;
  }

  input:focus ~ .placeholder {
    /* color: #dc2f55; */
  }
  @media (min-width: 992px) {
    margin-top: 60px;
  }
`

const Error = styled.label`
  visibility: hidden;
  font-family: Test Söhne;
  font-size: 10px;
  line-height: 160%;
  letter-spacing: 0.5px;
  color: #d50d0d;
  margin-top: 12px;
`

const Input = ({ placeholder, name, error, className, ...others }) => {
  return (
    <Container className={className}>
      <input
        {...others}
        id={name}
        name={name}
        placeholder=" "
        className={className}
      />
      <label htmlFor={`#${name}`} className="placeholder">
        {placeholder}
      </label>
      <Error>{error}</Error>
    </Container>
  )
}

Input.propTypes = {}

export default Input
