import React from "react"
import PropTypes from "prop-types"

import { StaticImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"
import Logo from "../../images/bellasurf-logo.svg"
import { Container1440 } from "../customStyledComponents"

const Grid = styled.div`
  display: grid;
  height: var(--app-height);
  //min-height: 800px;
`
const GridContent = styled(Container1440)`
  grid-area: 1/1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 48px 32px 32px 32px;
  @media (min-width: 576px) {
    padding: 48px;
  }
`

const LogoContainer = styled.div`
  display: flex;
  padding-bottom: 48px;
  height: fit-content;
  img {
    margin: 0;
  }
`

const LogoTextContainer = styled.div`
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const LogoText = styled.p`
  font-family: Test Founders Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 28px;
  color: #ffffff;
  margin: 0px;
`

const TextContainer = styled.div`
  max-width: 696px;
`

const Title = styled.p`
  font-family: Test Founders Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 44px;
  line-height: 52px;
  color: #ffffff;
  mix-blend-mode: normal;
  margin: 0px 0px;
  padding-bottom: 16px;

  @media (min-width: 576px) {
    font-size: 68px;
    line-height: 76px;
  }
`

const Description = styled.p`
  font-family: Test Söhne;
  font-size: 20px;
  line-height: 32px;
  color: #ffffff;

  mix-blend-mode: normal;
  align-self: stretch;
  margin: 0px 0px;
  padding-bottom: 32px;
  max-width: 477px;
`

const Button = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 24px 32px;
  background: #ffaf50;
  border-radius: 2px;
  width: fit-content;
  cursor: pointer;

  font-family: Test Founders Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;
  transition: background-color ease 300ms;
  &:hover {
    //background: #ffaf50aa;
    background-color: #e38d27;
  }
`
const Action = styled.a`
  text-decoration: none;
`
const SectionImage = props => {
  return (
    <>
      <Grid>
        <StaticImage
          src="../../images/splash.png"
          formats={["auto", "webp", "avif"]}
          alt="Bella Surf background"
          layout="fullWidth"
          placeholder="blurred"
          style={{ gridArea: `1/1` }}
        />
        <GridContent>
          <LogoContainer>
            <img src={Logo} />
            <LogoTextContainer>
              <LogoText>BELLA</LogoText>
              <LogoText>SURF</LogoText>
              <LogoText>INN</LogoText>
            </LogoTextContainer>
          </LogoContainer>
          <TextContainer>
            <Title>DISCOVER THE BEAUTY OF PUERTO RICO</Title>
            <Description>
              This charming hotel located in Isabela has a view of the beautiful
              Jobos Beach, with direct access from the deck to said beach
            </Description>
            <Action
              href="https://hotels.cloudbeds.com/reservation/ncSmNc"
              target="_blank"
            >
              <Button>Book now</Button>
            </Action>
          </TextContainer>
        </GridContent>
      </Grid>
    </>
  )
}

SectionImage.propTypes = {}

export default SectionImage
