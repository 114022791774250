import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Pagination, Navigation, Autoplay, Thumbs } from "swiper"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Slide from "react-reveal/Slide"
import LightBox from "../subcomponents/ligthBox"

// Import Swiper styles
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
import "swiper/css/autoplay"

import { Container1440 } from "../customStyledComponents"
import RoomCard from "../subcomponents/roomCard"

import "./sectionRooms.scss"
import LeftArrow from "../../images/left-arrow.svg"
import RightArrow from "../../images/right-arrow.svg"

SwiperCore.use([Pagination, Navigation, Autoplay])

const Container = styled.div`
  background: #132f60;
`
const Header = styled.p`
  font-family: Test Founders Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 32px;
  color: #ffffff;
  mix-blend-mode: normal;
  margin: 0px 0px;
  text-transform: uppercase;
  padding-bottom: 32px;
`
const ContentContainer = styled(Container1440)`
  padding-left: 32px;
  padding-top: 88px;
  padding-bottom: 88px;
  @media (min-width: 576px) {
    padding-left: 48px;
  }
`

const Spacer = styled.div`
  width: 24px;
`
const SectionRooms = props => {
  const data = useStaticQuery(graphql`
    query {
      room1: file(relativePath: { eq: "room1.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 452
            height: 291
            placeholder: BLURRED
          )
        }
      }
      room2: file(relativePath: { eq: "room2.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      room3: file(relativePath: { eq: "room3.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 452
            height: 291
            placeholder: BLURRED
          )
        }
      }

      room4: file(relativePath: { eq: "room4.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 452
            height: 291
            placeholder: BLURRED
          )
        }
      }

      room5: file(relativePath: { eq: "room5.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 452
            height: 291
            placeholder: BLURRED
          )
        }
      }

      room6: file(relativePath: { eq: "room6.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 452
            height: 291
            placeholder: BLURRED
          )
        }
      }

      room7: file(relativePath: { eq: "room7.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 452
            height: 291
            placeholder: BLURRED
          )
        }
      }

      room20: file(relativePath: { eq: "room2.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }

      room21: file(relativePath: { eq: "room21.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }

      room22: file(relativePath: { eq: "room22.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }

      room23: file(relativePath: { eq: "room23.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }

      room24: file(relativePath: { eq: "room24.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }

      room25: file(relativePath: { eq: "room25.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }

      room26: file(relativePath: { eq: "room26.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }

      room30: file(relativePath: { eq: "room3.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }

      room31: file(relativePath: { eq: "room31.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }

      room32: file(relativePath: { eq: "room32.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }

      room33: file(relativePath: { eq: "room33.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }

      room34: file(relativePath: { eq: "room34.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }

      room40: file(relativePath: { eq: "room4.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }

      room41: file(relativePath: { eq: "room41.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }

      room42: file(relativePath: { eq: "room42.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }

      room50: file(relativePath: { eq: "room5.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }

      room51: file(relativePath: { eq: "room51.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }

      room52: file(relativePath: { eq: "room52.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }

      room53: file(relativePath: { eq: "room53.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }

      room54: file(relativePath: { eq: "room54.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }

      room55: file(relativePath: { eq: "room55.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }

      room60: file(relativePath: { eq: "room6.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }

      room61: file(relativePath: { eq: "room61.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }

      room62: file(relativePath: { eq: "room62.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }

      room63: file(relativePath: { eq: "room63.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }

      room64: file(relativePath: { eq: "room64.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }

      room70: file(relativePath: { eq: "room7.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }

      room71: file(relativePath: { eq: "room71.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }

      room72: file(relativePath: { eq: "room72.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }

      room73: file(relativePath: { eq: "room73.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }

      room74: file(relativePath: { eq: "room74.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }

      room75: file(relativePath: { eq: "room75.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }

      room76: file(relativePath: { eq: "room76.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
    }
  `)

  const items = [
    {
      image: data.room2,
      title: "King Suite Apartment",
      description:
        "An oceanfront apartment on Jobos beach, includes 2 King size beds, a balcony, a private bathroom, a kitchen, parking, a coffee maker, WIFI, cable TV, linens and towels.",
      url: "https://hotels.cloudbeds.com/reservation/ncSmNc",
      details: [
        "4 guests",
        "2 King Beds",
        "1 Bath",
        "1 Balcony",
        "1 Kitchen",
        "1 Parking space",
      ],
      key: "2",
      subImages: [
        data.room20,
        data.room21,
        data.room22,
        data.room23,
        data.room24,
        data.room25,
        data.room26,
      ],
    },
    {
      image: data.room3,
      title: "Deluxe King Apartment",
      description:
        "An oceanfront Jobos apartment with two King beds, kitchen, two private bathrooms and a parking space.",
      url: "https://hotels.cloudbeds.com/reservation/ncSmNc",
      details: [
        "4 guests",
        "2 King Beds",
        "2 Baths",
        "1 Kitchen",
        "1 Parking space",
      ],
      key: "3",
      subImages: [
        data.room30,
        data.room31,
        data.room32,
        data.room33,
        data.room34,
      ],
    },
    {
      image: data.room4,
      title: "Queen Studio",
      description:
        'A private studio with a grand view of "Playa Jobos" in Isabela, 1 private bathroom, 1 parking space, 1 queen bed, kitchen, balcony, sheets and towels.',
      url: "https://hotels.cloudbeds.com/reservation/ncSmNc",
      details: ["2 guests", "1 Queen Bed", "1 Bath", "1 Kitchen", "1 Balcony"],
      key: "4",
      subImages: [data.room40, data.room41, data.room42],
    },
    {
      image: data.room5,
      title: "Queen Suite Studio",
      description:
        'A private studio with a grand view of "Playa Jobos" in Isabela, 1 private bathroom, 1 parking space, 2 queen beds, kitchen, balcony, sheets and towels.',
      url: "https://hotels.cloudbeds.com/reservation/ncSmNc",
      details: [
        "4 guests",
        "2 Queen Beds",
        "1 Bath",
        "1 Kitchen",
        "1 Balcony",
        "1 Parking space",
      ],
      key: "5",
      subImages: [
        data.room50,
        data.room51,
        data.room52,
        data.room53,
        data.room54,
        data.room55,
      ],
    },
    {
      image: data.room6,
      title: "King Apartment",
      description:
        "An oceanfront Jobos apartment with one King bed, one Twin size bed, kitchen, two private bathrooms, and one parking space.",
      url: "https://hotels.cloudbeds.com/reservation/ncSmNc",
      details: [
        "4 guests",
        "1 Queen Bed",
        "1 Twin bed",
        "2 Baths",
        "1 Kitchen",
        "1 Parking space",
      ],
      key: "6",
      subImages: [
        data.room60,
        data.room61,
        data.room62,
        data.room63,
        data.room64,
      ],
    },
    {
      image: data.room7,
      title: "Deluxe Queen Studio",
      description:
        "Two queen beds in a studio apartment that includes a living area, kitchen, bathroom and balcony with a view or deck.",
      url: "https://hotels.cloudbeds.com/reservation/ncSmNc",
      details: ["4 guests", "2 Queen Beds", "1 Bath", "1 Balcony", "1 Kitchen"],
      key: "7",
      subImages: [
        data.room70,
        data.room71,
        data.room72,
        data.room73,
        data.room74,
        data.room75,
        data.room76,
      ],
    },
  ]

  const [isOpen, setIsOpen] = useState(false)
  const [srcLigthBox, setSrcLigthBox] = useState([])

  return (
    <Container>
      <ContentContainer>
        <Header>Rooms</Header>
        <LightBox
          srcLigthBox={srcLigthBox}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
        />
        <Slide up>
          <Swiper
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            lazy={true}
            pagination={{
              type: "progressbar",
            }}
            navigation={{
              prevEl: ".prev",
              nextEl: ".next",
            }}
            slidesPerView={"auto"}
            spaceBetween={32}
            className="roomSwiper"
            breakpoints={{
              756: {
                spaceBetween: 48,
              },
            }}
          >
            {items.map(
              ({ image, title, description, details, url, subImages, key }) => (
                <SwiperSlide key={key}>
                  <RoomCard
                    setIsOpen={setIsOpen}
                    setSrcLigthBox={setSrcLigthBox}
                    image={image}
                    title={title}
                    description={description}
                    url={url}
                    details={details}
                    subImages={subImages}
                  ></RoomCard>
                </SwiperSlide>
              )
            )}
            <div slot="container-end">
              <div className="customSection">
                <div className="prev">
                  <img src={LeftArrow} />
                </div>
                <Spacer />
                <div className="next">
                  <img src={RightArrow} />
                </div>
                {/*  <Spacer /> */}
              </div>
            </div>
          </Swiper>
        </Slide>
      </ContentContainer>
    </Container>
  )
}

SectionRooms.propTypes = {}

export default SectionRooms
