import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import CollageTextCard from "../subcomponents/collageTextCard"
import elipse from "../../images/elipse.svg"
import angle from "../../images/angle.svg"
import { Container1440 } from "../customStyledComponents"
import CollageImageCard from "../subcomponents/collageImageCard"
import Elipse from "../../images/elipse.svg"
import Angle from "../../images/angle.svg"
import NoElipseOrange from "../../images/no-elipse-orange.svg"
import NoElipsePink from "../../images/no-elipse.svg"

const Container = styled(Container1440)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media (min-width: 1440px) {
    flex-wrap: nowrap;
    max-width: 100%;
  }
`

const ColorBar = styled.div`
  mix-blend-mode: normal;
  height: 18px;
  width: 100%;
  background-color: ${props =>
    props.backColor ? props.backColor : "transparent"};
  @media (min-width: 576px) {
    height: 44px;
  }
`

const ColorBarSpace = styled.div`
  width: 100%;
  flex-grow: 2;
`

const CardText = styled(CollageTextCard)`
  & > div:first-of-type {
    @media (min-width: 780px) {
      padding: 32px;
    }

    @media (min-width: 1024px) {
      padding: 72px;
    }
  }
`
const Card1 = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`

const Card3 = styled.div`
  display: flex;
  //opacity: 0.3;

  .card3-img {
    /* @media (min-width: 1440px) { */
    @media (min-width: 780px) {
      position: absolute;
      z-index: 4;
    }
  }
`

const Card5 = styled.div`
  display: flex;
  //opacity: 0.3;

  img {
    margin: 0px;
  }
`
const contentItems = [
  {
    backColor: "#FFEBED",
    color: "#132F60",
    header: "PADDLE BOARDING",
    title: "Join us on the water! Stand up and Paddle",
    description: "Enjoy a relaxing way to explore Jobos Beach.",
    url: "https://es-la.facebook.com/alohasurfschoolandrental/",
    linkName: "EQUIPMENT RENTAL",
  },
  {
    backColor: "#51A28E",
    color: "#FFFFFF",
    header: "GIRLS SURFING TRIP",
    title: "Let us help you plan everything you will need to surf up.",
    description: "Jobos Beach is known for its world class surfing.",
    url: "/#form",
    internal: true,
    linkName: "CONTACT US",
  },
  {
    backColor: "#132F60",
    color: "#FFFFFF",
    header: "MOUNTAIN BIKING",
    title: "Mount your bike  along the Paseo Lineal",
    description:
      "Paseo Lineal is a 14.3 kilometer lightly trafficked out and back trail located nearby that features beautiful wild flowers and is good for all skill levels. ",
    url: "https://www.alltrails.com/trail/puerto-rico/west-region/paseo-lineal",
    linkName: "VIEW TRAIL",
  },
]
const SectionCollage = props => {
  const breakpoints = useBreakpoint()
  return (
    <>
      {breakpoints.collagethree && (
        <>
          <Container>
            <CardText {...contentItems[0]} fwl>
              <Card1>
                <ColorBarSpace />
                <ColorBar backColor="#51BFA5" />
                <ColorBar backColor="#132F60" />
              </Card1>
            </CardText>
            <CollageImageCard
              image={
                <StaticImage
                  src="../../images/surftable.png"
                  formats={["auto", "webp", "avif"]}
                  alt="Bella Surf background"
                  layout="fullWidth"
                  imgStyle={{
                    objectFit: "cover",
                  }}
                  className="coverImage"
                />
              }
            ></CollageImageCard>
            <CardText {...contentItems[1]} fwr>
              <Card3>
                <img
                  src={Elipse}
                  style={{
                    width: `300px`,
                    height: `318px`,
                    mixBlendMode: `multiply`,
                  }}
                  className="card3-img"
                />
              </Card3>
            </CardText>
          </Container>
          <Container>
            <CollageImageCard
              fwl
              image={
                <StaticImage
                  src="../../images/bicicle.png"
                  formats={["auto", "webp", "avif"]}
                  alt="Bella Surf background"
                  layout="fullWidth"
                  imgStyle={{
                    objectFit: "cover",
                    objectPosition: "top",
                  }}
                  className="coverImage"
                />
              }
              mask={NoElipseOrange}
            ></CollageImageCard>
            <CardText {...contentItems[2]} image={Angle}></CardText>
            <CollageImageCard
              fwr
              image={
                <StaticImage
                  src="../../images/woman.png"
                  formats={["auto", "webp", "avif"]}
                  alt="Bella Surf background"
                  layout="fullWidth"
                  imgStyle={{
                    objectFit: "cover",
                  }}
                  className="coverImage"
                />
              }
              mask={NoElipsePink}
            ></CollageImageCard>
          </Container>
        </>
      )}
      {!breakpoints.collagethree && breakpoints.collagetwo && (
        <>
          <Container>
            <CardText {...contentItems[0]} flex>
              <Card1>
                <ColorBarSpace />
                <ColorBar backColor="#51BFA5" />
                <ColorBar backColor="#132F60" />
              </Card1>
            </CardText>
            <CollageImageCard
              fwr
              flex
              image={
                <StaticImage
                  src="../../images/surftable.png"
                  formats={["auto", "webp", "avif"]}
                  alt="Bella Surf background"
                  layout="fullWidth"
                  imgStyle={{
                    objectFit: "cover",
                  }}
                  className="coverImage"
                />
              }
            ></CollageImageCard>
            <CardText {...contentItems[1]} flex>
              <Card3>
                <img
                  src={Elipse}
                  style={{
                    width: `300px`,
                    height: `318px`,
                    mixBlendMode: `multiply`,
                  }}
                  className="card3-img"
                />
              </Card3>
            </CardText>
          </Container>

          <Container>
            <CollageImageCard
              fwr
              flex
              image={
                <StaticImage
                  src="../../images/bicicle.png"
                  formats={["auto", "webp", "avif"]}
                  alt="Bella Surf background"
                  layout="fullWidth"
                  imgStyle={{
                    objectFit: "cover",
                    objectPosition: "top",
                  }}
                  className="coverImage"
                />
              }
              mask={NoElipseOrange}
            ></CollageImageCard>

            <CardText {...contentItems[2]} fwl flex image={Angle}></CardText>
            <CollageImageCard
              fwr
              flex
              image={
                <StaticImage
                  src="../../images/woman.png"
                  formats={["auto", "webp", "avif"]}
                  alt="Bella Surf background"
                  layout="fullWidth"
                  imgStyle={{
                    objectFit: "cover",
                  }}
                  className="coverImage"
                />
              }
              mask={NoElipsePink}
            ></CollageImageCard>
          </Container>
        </>
      )}
      {!breakpoints.collagetwo && (
        <Container>
          <CardText {...contentItems[0]} center>
            <Card1>
              <ColorBarSpace />
              <ColorBar backColor="#51BFA5" />
              <ColorBar backColor="#132F60" />
            </Card1>
          </CardText>
          <CollageImageCard
            center
            image={
              <StaticImage
                src="../../images/surftable.png"
                formats={["auto", "webp", "avif"]}
                alt="Bella Surf background"
                layout="fullWidth"
                imgStyle={{
                  objectFit: "cover",
                }}
                className="coverImage"
              />
            }
          ></CollageImageCard>
          <CardText {...contentItems[1]} center>
            <Card3>
              <img
                src={Elipse}
                style={{
                  width: `300px`,
                  height: `318px`,
                  mixBlendMode: `multiply`,
                }}
                className="card3-img"
              />
            </Card3>
          </CardText>
          <CollageImageCard
            center
            image={
              <StaticImage
                src="../../images/bicicle.png"
                formats={["auto", "webp", "avif"]}
                alt="Bella Surf background"
                layout="fullWidth"
                imgStyle={{
                  objectFit: "cover",
                  objectPosition: "top",
                }}
                className="coverImage"
              />
            }
            mask={NoElipseOrange}
          ></CollageImageCard>
          <CardText {...contentItems[2]} center image={Angle}></CardText>
          <CollageImageCard
            center
            image={
              <StaticImage
                src="../../images/woman.png"
                formats={["auto", "webp", "avif"]}
                alt="Bella Surf background"
                layout="fullWidth"
                imgStyle={{
                  objectFit: "cover",
                }}
                className="coverImage"
              />
            }
            mask={NoElipsePink}
          ></CollageImageCard>
        </Container>
      )}
    </>
  )
}

SectionCollage.propTypes = {}

export default SectionCollage
