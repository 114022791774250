import React from "react"
import styled from "@emotion/styled"
import { Container1440 } from "../customStyledComponents"
import { ReactBridPlayer } from "react-brid-player-lib"
import Ellipse from "../../images/webcam-ellipse.svg"
import Live from "../../images/live.svg"

const Container = styled.div`
  display: flex;
  background-color: #132f60;
  // height: 598px;
`

const CardText = styled.div`
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: #ffebed;
  padding: 32px;

  @media (min-width: 576px) {
    padding: 72px;
  }

  @media (min-width: 780px) {
    padding: 32px;
  }

  @media (min-width: 1024px) {
    padding: 1.333125rem 80px 80px;
  }

  @media (min-width: 1440px) {
    padding-left: calc(80px - (100vw - 1440px) / 2);
  }
`

const LeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #132f60;
  width: 100%;

  justify-content: space-between;
  align-content: space-between;

  @media (min-width: 780px) {
    width: 33.33%;
  }
`
const ContentContainer = styled(Container1440)`
  display: flex;
  flex-direction: column-reverse;
  @media (min-width: 576px) {
    padding: 0px 0px 0px 0px;
  }
  @media (min-width: 780px) {
    flex-direction: row;
  }
`

const Header = styled.h1`
  display: flex;
  font-family: Test Founders Grotesk;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 32px;
  color: #ffaf50;
  mix-blend-mode: normal;
  margin: 0px 0px;
  text-transform: uppercase;
  max-width: fit-content;
  width: 100%;
  @media (min-width: 576px) {
  }
  @media (min-width: 992px) {
    width: 100%;
  }
  @media (min-width: 1440px) {
    padding-right: 80px;
    width: 100%;
  }
`

const BackImg = styled.img`
  max-width: 13.5625rem;
  display: none;
  @media (min-width: 780px) {
    display: block;
  }
`

const Date = styled.h2`
  color: #ffebed;
  font-family: Test Söhne;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5rem;
  margin: 0px;
  text-transform: uppercase;
`

const LiveTag = styled.div`
  margin-top: 1rem;
  display: flex;
  font-family: "Test Founders Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.1875rem;

  color: #ffebed;

  mix-blend-mode: normal;

  text-align: middle;
  align-items: center;
`

const IconImg = styled.img`
  width: 23px;
  height: 14px;
  margin-right: 0.5rem;
`

const VideoPlayer = styled.div`
  background-color: #132f60;
  width: 100%;
  height: 22.5rem;

  @media (min-width: 780px) {
    width: 66.66%;
    height: 37.375rem;
    // height: calc(.62 * 66vw)
  }
`

const data = {
  date: "aug 31",
  heading: "WEBCAM BELLA SURF INN",
}

const SectionStream = () => {
  const { date, heading } = data
  return (
    <Container>
      <ContentContainer>
        <LeftPanel>
          <BackImg src={Ellipse} />
          <CardText>
            <Date>{date}</Date>
            <Header>{heading}</Header>
            <LiveTag>
              <IconImg src={Live} />
              LIVE
            </LiveTag>
          </CardText>
        </LeftPanel>

        {/* <ReactBridPlayer
          divId="Brid_48754266"
          id="33749"
          width="640"
          height="360"
          video="983947"
        /> */}
        <VideoPlayer>
          <ReactBridPlayer
            divId="Brid_17254486"
            id="37410"
            width="100%"
            height="100%"
            video="1110940"
          />
        </VideoPlayer>
        {/* <VideoPlayer /> */}
      </ContentContainer>
    </Container>
  )
}

export default SectionStream
